import { Controller } from "@hotwired/stimulus"
import { useIntersection } from 'stimulus-use'

// Connects to data-controller="navigation-item"
export default class extends Controller {
  options = {
    threshold: 0, // default
    eventPrefix: false
  }

  connect() {
    useIntersection(this, this.options)
  }

  appear(entry) {
    // remove existing active classes
    document.querySelectorAll('.nav-link').forEach(element => element.classList.remove("active"))
    document.querySelectorAll('.arrow-up').forEach(element => element.classList.add("d-none"))

    // add active class to the active nav link
    let navItem = document.getElementById(`${entry.target.id}-nav-link`)
    if (navItem) navItem.classList.add("active")
    // show the nav arrow
    let navArrow = document.getElementById(`${entry.target.id}-nav-arrow`)
    if (navArrow) navArrow.classList.remove("d-none")
  }

  disappear(entry) {
    // callback automatically triggered when the element
    // leaves the viewport (or root Element specified in the options)
  }
}
