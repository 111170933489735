import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="parallax-image"
export default class extends Controller {
  static values = {
    change: Number
  }

  connect() {
    let initalPostition = this.element.getBoundingClientRect().y
    let changePerPixel = this.changeValue / this.element.parentElement.offsetHeight

    addEventListener('scroll', () => {
      let newPosition = initalPostition - (changePerPixel * window.scrollY)
      this.element.style.top = `${newPosition}px`
    });
  }
}
