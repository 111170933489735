import { Controller } from "@hotwired/stimulus"
import { useIntersection } from 'stimulus-use'

// Connects to data-controller="observer"
export default class extends Controller {
  static values = {
    options: Object,
    previousElementId: String,
  }
  static targets = [ "iframe" ]
  static values = { src: String }

  defaultOptions = {
    threshold: 0,
    eventPrefix: false
  }

  connect() {
    useIntersection(this, {...this.defaultOptions,...this.optionsValue})
  }

  appear(entry) {
    // callback automatically triggered when the element
    // intersects with the viewport (or root Element specified in the options)
    if (this.hasIframeTarget) {
      this.iframeTarget.src = this.srcValue
    }
  }

  disappear(entry) {
    // callback automatically triggered when the element
    // leaves the viewport (or root Element specified in the options)
    if (this.hasIframeTarget) {
      this.iframeTarget.src = ""
    }
  }

  shutterIn(event) {
    const { controller, entry } = event.detail
    entry.target.classList.add("is-visible")
  }

  shutterOut(event) {
    const { controller, entry } = event.detail
    entry.target.classList.remove("is-visible")
  }

  resetChildren(event) {
    const { controller, entry } = event.detail
    const visibleChildren = entry.target.querySelectorAll(".is-visible")

    visibleChildren.forEach(child => child.classList.remove("is-visible"))
  }

  fadeOutPrevious(event) {
    const { controller, entry } = event.detail

    if (this.previousElementIdValue) {
      const previousElement = document.querySelector(this.previousElementIdValue)
      previousElement.style.opacity = 1 - entry.intersectionRatio
    }
  }

  fadeInPrevious(event) {
    const { controller, entry } = event.detail

    if (this.previousElementIdValue) {
      const previousElement = document.querySelector(this.previousElementIdValue)
      previousElement.style.opacity = 1
    }
  }
}
