import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="department-modal"
export default class extends Controller {
  static targets = [ "modal" ]

  connect() {
  }

  showModal({ params: {title, content, imageUrl, id, lastId, firstId}}) {
    const modal = new Modal(this.modalTarget, {})
    modal.show()

    this.populateModal(title, content, imageUrl, id, lastId, firstId)
  }
  
  populateModal(title, content, imageUrl, id, lastId, firstId) {
    this.modalTarget.querySelector('.modal-title').textContent = title
    this.modalTarget.querySelector('.department-modal-content').innerHTML = content
    this.modalTarget.querySelector('img').src = imageUrl
    this.modalTarget.querySelector('.hidden-department-id-div').innerHTML = `<p>${id}</p>`

    let prev_btn = document.querySelector("#previous")
    let next_btn = document.querySelector("#next")
    if (firstId == id) {
      prev_btn.style.display = "none"
      next_btn.style.display = "block"
    } else if (lastId == id){
      prev_btn.style.display = "block"
      next_btn.style.display = "none"
    } else {
      prev_btn.style.display = "block"
      next_btn.style.display = "block"
    }
  }

  changeModal({params: {modifier, lastId, firstId}}) {
    let id = parseInt(document.querySelector('.hidden-department-id-div').textContent)
    let new_department = document.querySelector(`#department_${id + parseInt(modifier)}`)

    if (new_department) {
      this.populateModal(
        new_department.getAttribute("data-department-modal-title-param"),
        new_department.getAttribute("data-department-modal-content-param"),
        new_department.getAttribute("data-department-modal-image-url-param"),
        new_department.getAttribute("data-department-modal-id-param"),
        lastId,
        firstId
      )
    }
  }
}
